import { tv } from 'tailwind-variants';

export const container = tv({
  base: 'flex gap-x-2 font-sans text-sm',
});

export const comparisonPriceContainer = tv({
  base: 'space-between flex flex-wrap gap-x-1',
});

export const mainPriceParagraph = tv({
  base: 'whitespace-nowrap font-semibold',
});

export const comparisionPriceParagraph = tv({
  base: 'text-grey-300 whitespace-nowrap',
});
