import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/ui/components/blocks/RichTextAccordion.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/components/blocks/Store.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselPrevious","CarouselNext"] */ "/app/src/ui/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/Frame/Frame.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/components/Heading/Heading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/icon/icon.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/components/Link/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/components/ProductList/ProductListCarouselItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LazyRichText"] */ "/app/src/ui/components/RichText/LazyRichText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/popups/forms/BookMeetingForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/popups/forms/ClaimsForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/popups/forms/ContactForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/popups/forms/MatchPriceForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/popups/forms/NewCustomerDiscountForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/popups/forms/ReturnsForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/utils/imageLoader.ts");
